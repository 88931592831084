/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */
function menuitems_tabindex(action) {
  var menuItems = jQuery('#engagednow-component-navigation li');
  if(menuItems.length>0) {
    switch(action) {
        case 'open':
            menuItems.each(function (index) {
              jQuery(this).attr('tabindex', 1);
            });
            break;
        case 'close':
            menuItems.each(function (index) {
              jQuery(this).attr('tabindex', -1);
            });
            break;
        default:
          menuItems.each(function (index) {
            jQuery(this).attr('tabindex', -1);
          });
    }
  }
}

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {

        $("#arrow-down").on("click", function() {
          var c = $(".content-skip");
          $("html, body").animate({
            scrollTop: $(c).offset().top - 80
          }, 100);
          return false;
        });

        // Move window when opened from an anchor link
        var hasHash = window.location.hash.substr(1);
        if(location.hash.length !== 0) {
          window.setTimeout(function () {
              window.scrollTo(window.scrollX, window.scrollY - 80);
          }, 2000);
        }

        menuitems_tabindex('close');
        var toggled = 0;
        $('body').attr( 'data-nav-visible', 'false' );
        
        $('#header-navmenu-on').on('click',function (e) {
          e.preventDefault();
            if(toggled === 0){
              //$('.menu-switcher').addClass( 'opened' );
              toggled++;
              $('body').attr( 'data-nav-visible', 'true' );
              $(this).attr( 'aria-expanded', 'true' );
              //$('.nav-primary').addClass('opened').attr( 'tabindex', '0' );
              $('body').addClass('noscroll');
              $('#engagednow-component-navigation li:first-child').focus();
              menuitems_tabindex('open');
            }
            else{
              //$('.menu-switcher').removeClass( 'opened' );
              toggled--;
              $('body').attr( 'data-nav-visible', 'false' );
              $(this).attr( 'aria-expanded', 'false' );
              //$('.nav-primary').delay().removeClass('opened').attr( 'tabindex', '-1' );
              $('body').removeClass('noscroll');
              $('#header-navmenu-on').focus();
              menuitems_tabindex('close');
            }
        });

        $('#header-navmenu-on').keypress(function(event){
          if(event.keyCode === 13){
            $('#header-navmenu-on').click();
          }
        });

        $('#panes a').on('focus',function (e) {
          $(this).parent().addClass('hover');
        });

        $('#panes a').on('focusout',function (e) {
          $(this).parent().removeClass('hover');
        });

      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
        $( '#mason-catalogue div.hover-state h3 .inner-text span' ).wrap('<span></span>');
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    'drinks': {
      init: function() {
        $( '#catalogue ul li .hover-state h3 .inner-text span' ).wrap('<span></span>');
      }
    },
    'products': {
      init: function() {
        $( '#catalogue ul li .hover-state h3 .inner-text span' ).wrap('<span></span>');
      }
    },
    'casa_martini': {
      init: function() {
        $( '#hero_medium_6 .inline-buttons span:first-child a' ).attr('src', 'javascript:void(0)');
      }
    },
    'we_are_martini': {
      init: function() {
        windowWidth = $(window).width();
        if(windowWidth<699) {
          $( '#hero_medium_16 .en-hero-text, #engagednow-component-carousel .en-hero-text' ).remove();
          $( '#engagednow-component-carousel .carousel-pagination' ).css({
            'position' : 'absolute'
          });
        }
      }
    },
    'martini_bitter': {
      init: function() {
        windowWidth = $(window).width();
        if(windowWidth<700) {
          var carouselHeight = $( '#hero_medium_3 .en-hero-text' ).height();
          $( '#hero_medium_4 .en-hero-text' ).height( carouselHeight );
        }
      }
    },
    'martini_bianco': {
      init: function() {
        windowWidth = $(window).width();
        if(windowWidth<700) {
          var carouselHeight = $( '#hero_medium_3 .en-hero-text' ).height();
          $( '#hero_medium_4 .en-hero-text, #hero_medium_5 .en-hero-text' ).height( carouselHeight );
        }
      }
    },
    'martini_extra_dry': {
      init: function() {
        windowWidth = $(window).width();
        if(windowWidth<700) {
          var tes = $( '#hero_medium_4 .en-hero-image-text .en-hero-text');
          if(tes.length<=1){
            tes.remove();
          }
        }
      }
    },
    'riserva_speciale_ambrato': {
      init: function() {
        windowWidth = $(window).width();
        if(windowWidth<700) {
          $( '#hero_medium_2 .en-hero-image-text' ).css({
            'color': '#000'
          });
        }
        $(window).resize(function() {
          windowWidth = $(window).width();
          if(windowWidth<700) {
            $( '#hero_medium_2 .en-hero-image-text' ).css({
              'color': '#000'
            });
          } else {
            $( '#hero_medium_2 .en-hero-image-text' ).css({
              'color': '#fff'
            });
          }
        });
      }
    },
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  $('.sub-menu span').after('<span class="toggle"></span>');

  $('.sub-menu .toggle').click(function(){
	$('.header_navigation ul').toggleClass('open');
	$('.toggle').toggleClass('open');
  });


  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
